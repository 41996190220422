.MuiList-root {
    &.profile-login-details {
        display: flex;
        margin: 0 0 2rem 0;

        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }
    .select-Sms {
        text-transform: uppercase;
    }

}
.MuiListItem-root {
    .MuiFormControlLabel-root {
        margin: 0;

        .MuiFormControlLabel-label {
            &.Mui-disabled {
                color: $brandTextColour;
            }
        }
    }
    .MuiSwitch-root {
        .MuiSwitch-switchBase {
            &.Mui-checked {
                color: $brandColourPrimary;

                +.MuiSwitch-track {
                    background-color: $brandColourPrimary;
                }

                &.Mui-disabled {
                    color: $brandColourPrimary;
                    opacity: 1; 

                    +.MuiSwitch-track {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}
.profile-personal-info {
    .MuiFormControl-root {
        .MuiInputBase-input.Mui-disabled {
                -webkit-text-fill-color: $brandTextColour !important;
        }
        .MuiInputLabel-root {
            .MuiSvgIcon-root {
                color: $brandColourPrimary;
            }
        }
    }

    .MuiTypography-root {
        margin: 0 0 1.5rem 0.8rem;
    }
}

@media only screen and (max-width: $mobileBreak) {
    .MuiList-root {
        &.profile-login-details {
            flex-wrap: wrap;
        }
    }
    .profile-personal-info {
        h4 {
            padding-bottom: 0.6rem;
        }
    }
}