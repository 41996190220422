.login-history{
    .session-header {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        h1 {
            color: $brandColourPrimary;
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 0;
        }
        .MuiButton-root {
            border-radius: 25px;
            margin-left: 1rem;
            font-size: 0.8rem;
            line-height: 1.2rem;
        }
    }
    p, ul, span {
        font-size: 0.9rem;
    }
    a {
        color: $brandColourPrimary;
    }
    > div:not(:first-of-type) {
        margin-top: 1rem;

        h3 {
            margin: 2.5rem 0 0.5rem 0;
        }
    }

    .inline-text-btn{
        border: none;
        background-color: #fff;
        color: $brandColourPrimary;
        padding: 0;
    }

    .session-list {
        margin-top: 2.5rem;

        th {
            width: 12%;

            &:last-child {
                width: 52%;
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .login-history {
        .session-header {   
            text-align: center;
            justify-content: center;
            margin: 1.2rem 0 2rem 0;

            h1 {
                font-size: 1.7rem;
                margin-bottom: 1rem;
                text-align: center;
            }

            .quick-actions {
                width: 100%;
            }
        }
    }
}