.header {
    .sub-wrap {
        padding: 0;
        @if $fullWidth {
            max-width: unset;
        }
    }
}

.header-wrapper {
    display: flex;
}

.logo-wrapper {
    align-self: center;
}

.appbar .logo-wrapper {
    align-self: center;
    text-align: center;
}

.secondary-logo-wrapper {
    align-self: center;
    text-align: right;
}

.logo,
.secondary-logo {
    max-width: 210px;
    max-height: 40px;
}

@media only screen and (max-width: $mobileBreak) {
    .logo,
    .secondary-logo {
        max-width: 150px;
        max-height: 30px;
    }
    .secondary-logo {
        margin-left: 0;
    }
}