footer {
    background-color: $brandColourPrimary;
    color: white;
    display: flex;
    font-size: 0.8rem;
    padding: 0.5em 2em;
}

footer p {
    margin-bottom: 0;
    color: white;
}

footer .row {
    min-height: 50px;
}

.footer-links,
.footer-text {
    align-self: center;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        color: lighten($brandColourPrimary, 40);
    }
}

.footer-text {
    text-align: right;
}

.terms-and-conditions {
    margin-right: 10px;
}
.privacy-policy {
    margin-left: 10px;
}

@media only screen and (max-width: $mobileBreak) {
    .footer-links a {
        display: block;
    }
}

footer>.sub-wrap {
    padding: 0;
    @if $fullWidth {
        max-width: unset;
    }
}