
.money-table-header {
    min-width: 5em;
}

.transactiondays {
    font-size: 60%;
}

.transactionpage-moneyicon {
    white-space: nowrap;
}

.balance-header {
    white-space: nowrap;
}

// Mobile Transaction View
.money-credit.money-colorise {
    color: $green;
}
.money-pending-icon,
.money-pending.money {
    color: #ffa050;
}

.money-pending-icon {
    margin-right: 0.25rem;
}

.mobile-transaction-list-container {
    .card:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .card:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-item {
        display: flex;

        .left-item {
            flex-grow: 1;
        }

        .right-item {
            width: 9rem;
        }
    }
}

.mobile-transaction-detail-container {
    .btn.btn-secondary {
        margin-top: 1rem;
    }

    .card-header {
        display: flex;
    }

    .card-header .term {
        flex-grow: 1;
    }

    .card .list-group-item {
        display: flex;
        flex-direction: column;
        min-height: 5rem;
    }
}

// Desktop Transaction View
.transaction-description {
    margin-top: 0.5rem;
}
.transaction-description-item {
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 0;
}

.transaction-description-item-term {
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-left: 2.6rem;
    min-width: 10rem;
    width: 10rem;
}


.account-metric-head {
    h3 {
        margin: 0 0 0.8rem 0;
        font-size: 0.95rem;
    }

    .metric-group-container {
        column-count: 2;

        p {
            margin-bottom: 0.2rem;
            font-size: 0.85rem;
        }
        .metric-label {
            font-weight: 600;
        }
    }
}

.dashboard-page, .accountslist-page {
    .dashboard-banner {
        background-color: #F8F8F8;
        margin-bottom: 2rem;
        max-height: 200px;

        .user-details {
            height: 130px;
            display: table-cell;
            vertical-align: middle;

            h2 {
                color: lighten($brandTextColour, 25);
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: .2rem;
            }
            h3 {
                color: lighten($brandTextColour, 25);
                font-size: 2rem;
                font-weight: 600;
                margin-top: 0;
            }
        }

        .user-balances {
            .bal-title {
                color: lighten($brandTextColour, 25);
                font-size: 0.75rem;
                font-weight: 500;
            }
            h2 {
                color: $brandTextColour;
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0.8rem;
            }
            h3 {
                color: lighten($brandTextColour, 25);
                font-size: 1.4rem;
                font-weight: 500;
                margin-top: 0;
            }
        }

        .quick-actions {
            height: 130px;
            display: flex;
            vertical-align: middle;
            text-align: center;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            .MuiButton-root.MuiButton-contained {
                border-radius: 50px;
                font-weight: 500;
                letter-spacing: 0;
                margin-bottom: 0rem;
                padding: 8px 20px;
                text-transform: initial !important;
                width: 180px;
            }
        }
    }
    .accord-container {
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    }

    .accounts-list, .transactions-list {
        .list-header {
            margin-bottom: 1.4rem;
            padding-left: 0.5rem;
            display: flex;
            align-items: baseline;

            h2 {
                margin-bottom: 0;
            }
            .list-subhead {
                color: #9B9B9B;
                font-size: 0.8rem;
                font-weight: 500;
                margin-left: 1.5rem;
            }
        }
        .MuiGrid-root {
            align-items: center;
        }
        .MuiAccordion-root {
            box-shadow: 0 0 1px 0 rgba(0,0,0,0.20),
                        0 1px 1px 0 rgba(0,0,0,0.14),
                        0 1px 3px 0 rgba(0,0,0,0.12);
        
            &:before {
                display: none;
            }
        }
        .accordion-parent {
            .MuiAccordionSummary-root.accordion-summary-parent {
                min-height: 40px;

                &:hover {
                    background-color: #FFFFFF;
                }

                &.Mui-expanded {
                    background-color: #FFFFFF;
                }

                .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                    -webkit-transform: rotate(225deg);
                    -moz-transform: rotate(225deg);
                    -ms-transform: rotate(225deg);
                    transform: rotate(225deg);
                }
            }
        }
        .MuiAccordionSummary-root {
            min-height: 79px;
            font-size: 0.9rem;

            &:hover {
                background-color: #F8F8F8;
            }
            .MuiAccordionSummary-expandIconWrapper {
                .MuiSvgIcon-root {
                    width: 1.1rem;
                }
            }
            &.accordion-summary-parent {
                .dd-icon {
                    background-color: rgba(65, 105, 225, 0.25);
                    border-radius: 50px;
                    color: #4169E1;
                    padding: 3px;
                }
                .fee-icon {
                    background-color: rgba(0, 128, 0, 0.18);
                    border-radius: 50px;
                    color: #008000;
                    padding: 3px;
                }
                &.Mui-expanded {
                    background-color: #EFEFEF;
                }
            }
            &.accordion-summary-child {
                .account-icon {
                    background-color: #E9E9E9;
                    border-radius: 50px;
                    color: #9B9B9B;
                    padding: 0 4px;
                    height: 1.5rem;
                    width: 1.5rem;
                }
                &.Mui-expanded {
                    background-color: #F8F8F8;
                }
            }
        }
        .MuiAccordionSummary-content {
            margin-right: 1.2rem;
        }
        .MuiAccordionDetails-root {
            .button-row-center {
                text-align: center;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: space-evenly;
                align-items: center;

                .MuiButton-root {
                    display: flex;
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 0.75rem;

                    .MuiButton-startIcon {
                        margin: 0 0 0.3rem 0;
                    }
                }
            }
            // .button-row-split {
            //     border-top: 1px solid #EFEFEF;
            //     display: flex;
            //     justify-content: space-between;
            //     margin: 0.5rem 0;
            //     padding-top: 1.1rem;

            // }

            // .MuiButton-root {
            //     color: $brandColourPrimary;
            //     font-size: 0.75rem;
            //     margin-left: 1rem;

            //     &:only-child {
            //         margin: 0 auto !important;
            //     }

            //     &:first-of-type {
            //         margin-left: 0;
            //     }
            // }
            // .MuiButton-outlined {
            //     border: 0.1rem solid $brandColourPrimary;
            // }
            // .btn-view-account {
            //     padding: 0.4rem 0.9rem;
            // }
        }
        .banner-balance {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            margin-bottom: 0.2rem;

            &.main-balance {
                font-weight: 600;
            }
        }
        .button-row {
            margin: 2rem 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            align-content: center;

            .btn-showall {
                background-color: #E9E9E9;
                border-radius: 50px;
                color: #9B9B9B;
                font-weight: 500;
                letter-spacing: 0;
                margin-bottom: 0.6rem;
                text-transform: initial;
            }
        }
        .total-balance {
            font-size: 0.93rem;
            margin: 1.5rem 1rem 0 0;
            text-align: right;

            p {
                margin-bottom: 0.1rem;
            }
            .main-balance {
                font-weight: 600;
            }
            .avail-balance {
                margin-top: 0.8rem;
            }
        }
    }
    .accounts-list {
        .accordion-parent {
            box-shadow: none;

            .MuiAccordionSummary-root.accordion-summary-parent {
                min-height: 40px;

                &:hover {
                    background-color: #FFFFFF;
                }

                &.Mui-expanded {
                    background-color: #FFFFFF;
                }

                .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                    -webkit-transform: rotate(225deg);
                    -moz-transform: rotate(225deg);
                    -ms-transform: rotate(225deg);
                    transform: rotate(225deg);
                }
            }
        }
    }
    .transactions-list {
        .accord-container {
            max-height: 50rem;
            overflow-y: scroll;
    
            &::-webkit-scrollbar {
                width: 0.1em;
            }
        }
        .MuiAccordion-root.Mui-disabled {
            background-color: #fff;
        }
        .MuiAccordionSummary-root.Mui-disabled {
            opacity: 1;

            .MuiAccordionSummary-expandIconWrapper {
                display: none;
            }
        }

        .recent-activity-header {
            display: flex;
            align-items: center;
        }
        .ra-trans-date {
            color: #9B9B9B;
            font-size: 0.75rem;
            line-height: 1.1rem;
        }
        .ra-trans-type {
            font-weight: 500;
            line-height: 1.1rem;
        }
        .ra-trans-note {
            color: #9B9B9B;
            font-size: 0.75rem;
            line-height: 1.2rem;
        }
        .ra-trans-debit {
            font-weight: 500;
            line-height: 1.1rem;
            margin-bottom: 0;
            text-align: right;
        }
        .ra-trans-credit {
            color: #1a931a;
            font-weight: 500;
            line-height: 1.1rem;
            margin-bottom: 0;
            text-align: right;
        }
        .ra-trans-balance {
            color: #9B9B9B;
            font-size: 0.75rem;
            line-height: 1.2rem;
            margin-bottom: 0;
            text-align: right;
        }
    }
}

.accountslist-page {
    .button-row-showall {
        display: none !important;
    }
    .accord-container {
        box-shadow: none;
    }
}

// SINGLE ACCOUNT PAGE
.account-view {
    p {
        font-size: 0.9rem;
    }
    .account-header {
        margin: 0 0 3rem 0.7rem;

        .desc-title {
            color: $brandColourPrimary;
            font-size: 1.3rem;
            font-weight: 500;
            margin: 0;

            .edit-desc-title {
                margin-left: 0.3rem;

                svg {
                    font-size: 1.1rem;
                }
            }
            .button-row {
                margin-bottom: 0.5rem;
            }
            .account-name-edit {
                width: 250px;
            }
            .MuiButton-contained {
                padding: 0.6rem 3rem;
            }
        }
        .acc-num {
            font-size: 0.8rem;
        }
        .avail-title {
            font-size: 0.8rem;
            margin: 0;
        }
        .avail-bal {
            font-size: 1.5rem;
            font-weight: 500;
        }
        .curr-bal {
            font-size: 0.8rem;
        }
        .quick-actions {
            height: 130px;
            display: flex;
            vertical-align: middle;
            text-align: center;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-content: center;

            &.desktopActions {
                opacity: 1;
            }
            &.mobileActions {
                opacity: 0;
                display: none;
            }

            .MuiButton-root.MuiButton-contained {
                border-radius: 50px;
                font-weight: 500;
                letter-spacing: 0;
                margin: 0 0 0.3rem 0.5rem;
                padding: 8px 20px;
                text-transform: initial !important;
                width: 180px;
            }
        }
    }
    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
    }
    .transactiondays {
        font-size: 0.8rem;
        margin: 0 0 1rem 0.5rem;
        float: left;
        width: 100%;
    }
    .account-metrics {    
        p {
            margin-bottom: 0.4rem;

            .title {
                font-weight: 600;
            }
        }
    }
    .scheduled-transfers {
        h3 {
            color: $brandColourPrimary;
            font-size: 1.2rem;
            margin: 0 0 0.3rem 0;
        }
        .pending-transfers {
            margin-bottom: 3rem;
        }
        .no-transfers-row {
            p {
                font-size: 0.84rem;
                margin: 0;
            }
        }
    }
    .disbursement-details {
        h3 {
            color: $brandColourPrimary;
            font-size: 1.2rem;
            margin: 0 0 0.2rem 0;
        }
    }
}

@media only screen and (max-width: 1180px ) {
    .dashboard-page, .accountslist-page {
        .accounts-list {
            .MuiGrid-root,
            .MuiGrid-root>.MuiGrid-item {
                margin-left: 0;
                padding-left: 0;
            }
            .instrument-details {
                flex-basis: 100%;
                max-width: 100%;
            }
            .instrument-balances,
            .account-balances {
                padding-top: 10px;
                flex-basis: 100%;
                max-width: 100%;
            }
            .account-details-icon {
                flex-basis: 20%;
                max-width: 20%;
            }
            .account-details {
                flex-basis: 80%;
                max-width: 80%;
            }

            .account-metric-head {
                h3 {
                    font-size: 0.95rem;
                }
            
                .metric-group-container {
                    column-count: 1;
                }
            }
        }
        
        .accounts-list, .transactions-list {
            .list-header {
                h2 {
                    font-size: 1.3rem;
                }
            }
        }
    }
    .account-view {
        .account-header {
            .quick-actions {
                flex-direction: column;
                align-items: flex-end;
                flex-wrap: nowrap;
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 959px ) {
    .dashboard-page {
        .dashboard-banner {
            .user-details {
                h2 {
                    font-size: 0.9rem;
                }
                h3 {
                    font-size: 1.9rem;
                }
            }
            .user-balances {
                .bal-title {
                    font-size: 0.8rem;
                }
                h2, h3 {
                    font-size: 1.4rem;
                }
            }
        }

        .accounts-list {
            .MuiAccordionDetails-root {
                .button-row-split {
                    padding-top: 0.5rem;
                    flex-direction: column;
                    text-align: center;

                    .MuiButton-root {
                        margin: 0 0 0.5rem 0;

                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .list-transactions {
            .trans-icon.MuiGrid-root {
                max-width: 12%;
            }
            .trans-details.MuiGrid-root {
                max-width: 55%;
            }
            .trans-balances.MuiGrid-root {
                max-width: 33%;
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .content-wrapper {
        .dashboard-page, .accountslist-page {
            .sub-wrap {
                padding: 0;
            }
            .dashboard-banner {
                .MuiGrid-root.MuiGrid-item {
                    flex-basis: 100%;
                    max-width: 100%;
                }
                .user-details {
                    padding-left: 1rem;

                    h2 {
                        font-size: 0.9rem;
                    }
                    h3 {
                        font-size: 1.3rem;
                    }
                }
                .user-balances-container, .quick-actions-container {
                    display: none;
                }
            }
            .MuiGrid-root.list-accounts,
            .MuiGrid-root.list-transactions {
                flex-basis: 100%;
                max-width: 100%;
            }
            .list-transactions {
                display: none;
            }
            .MuiAccordion-root {
                border-radius: 0;
            }
            .accounts-list {
                .list-header {
                    padding: 0;
                    margin: 0 0 0.2rem 1rem;

                    h2 {
                        font-size: 1.2rem;
                    }
                }
                .MuiAccordionDetails-root {
                    .button-row-center {
                        padding: 0;
                    }
                    .button-row-split {
                        justify-content: center;
                        flex-direction: column;
                    }
                }
                .account-details-icon {
                    flex-basis: 12%;
                    max-width: 12%;
                }
                .account-balances {
                    margin-left: 2.4rem;
                }
                .MuiSvgIcon-root {
                    color: $brandColourPrimary;
                }
            }
            .quick-actions {
                display: none;
            }
        }
        .account-view {
            .account-header {
                margin: 1rem 0;
                text-align: center;

                .MuiGrid-root {
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    margin-left: -6px;
                    padding-left: 8px;
                    flex-basis: 100%;
                    max-width: 100%;
                    width: 100%;

                    .button-row {
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
        
                        .account-name-edit {
                            width: 100%;
                            text-align: center;
                            margin-bottom: 0.5rem;
                        }
                    }
                }
                .quick-actions {
                    align-items: center;
                    height: auto;

                    &.desktopActions {
                        opacity: 0;
                        display: none;
                    }

                    &.mobileActions {
                        opacity: 1;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: space-evenly;
                        align-items: center;
        
                        .MuiButton-root {
                            display: flex;
                            flex-direction: column;
                            font-weight: 500;
                            font-size: 0.75rem;
        
                            .MuiButton-startIcon {
                                margin: 0 0 0.3rem 0;
                                padding: 10px;
                                border-radius: 50%;
                                background: #f7f7f7;
                            }
                        }
                    }
                }
            }
            .transfers-mobile {
                .transfer-date {
                    background-color: rgba(0, 0, 0, 0.03);
                    padding: 0.5rem 0;

                    p {
                        margin-bottom: 0;
                        color: $brandColourPrimary;
                        font-weight: 500;
                        font-size: 0.9rem;
                    }
                }
                p {
                    margin: 0 0 0.2rem 1rem;
                    font-size: 0.8rem;

                    .money {
                        font-weight: 600;
                    }
                    .subtitle {
                        font-weight: 500;
                    }
                }
                .MuiButtonBase-root {
                    float: right;
                    margin-right: 0.5rem;

                    svg {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .content-wrapper {
        .account-view {
            &.sub-wrap {
                padding: 0;
            }

            .account-header {
                margin: 1rem 0;
                text-align: center;

                .MuiGrid-root {
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    flex-basis: 100%;
                    max-width: 100%;
                    width: 100%;
                }
                
            }
            .accountTabs {
                border-top: 1px solid rgba(0, 0, 0, 0.12);

                .MuiTabs-flexContainer {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }
                .MuiTabs-indicator {
                    display: none;
                }
                .MuiButtonBase-root.MuiTab-root.Mui-selected {
                    border-bottom: 2px solid;
                }
            }
            .account-metrics {    
                p {
                    font-size: 0.84rem;
                }
            }
            .scheduled-transfers-container {
                padding: 1.5rem 0;

                h3 {
                    font-size: 1.1rem;
                    margin: 0 1.2rem 0.3rem;
                }
                
                .intro {
                    margin: 0 1.2rem 1rem;
                }
                .transfers-mobile {
                    .transfer-date p {
                        margin-bottom: 0;
                    }
                    p {
                        margin: 0 0 0.2rem 1.2rem;
                    }
                }
            }
            .disbursements-container {
                padding: 2rem 0rem 2rem 1.2rem;
            }
        }
    }
}