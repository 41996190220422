.sub-wrap.firsttimelogin-page {
    display: flex;
    flex: 1;
    flex-direction: column;

    
    h1 {
        color: darken($brandTextColour, 15%);
        font-size: 2rem;
        margin: 0.6rem 0 1.4rem 0;
    }
    h2 {
        color: $brandColourPrimary;
        font-size: 1.2rem;
        font-weight: 600;
        margin: 2rem 0 0.6rem 0;
    }
}

.firsttimelogin-tc-container {
    position: relative;
    overflow: auto;
    flex: 1;
    margin-bottom: 3rem;
    padding-right: 3rem;
}

.firsttimelogin-tc-inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.firsttimelogin-tc-button-container {
    padding-top: 10px;
    display: inline-block;
    margin-bottom: 1rem;

    .MuiButton-root.MuiButton-contained {
        font-size: 0.8rem;
        font-weight: 600;
    }
}

.firsttimelogin-tc-button-container .btn-primary {
    float: right;
}

.reset-password-form {
    width: 500px;
    margin: 0 auto;
    .form-group {
        margin-bottom: 20px;
    }
}

.request-token-container {
    width: 100%;

    .request-token {
        text-align: right;
        margin: 0 0 0.7rem 0;
        min-height: 2.3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .MuiButton-text {
            color: #b5b5b5;

            &:hover {
                background-color: #f8f8f8;
            }
        }
        p {
            font-size: 0.9rem;
            margin: 0;
        }
    }
}

.authentication {
    .table {
        tr {
            &:hover {
                background-color: transparent;
            }
        }
        td {
            border-top: none;
            vertical-align: middle;

            &.row-title {
                font-weight: 600;
            }
        }
    }
}