.indue-status-buttongroup {
    min-width: 100px;
}

.container.sub-wrap.card-terms-page {
    display: flex;
    flex-direction: column;
}

.card-terms-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 2.5rem;
}

.card-terms-document-container {
    flex-grow: 1;
    justify-content: center;
    display: flex;
    max-height: 700px;
}

.card-terms-document {
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-terms-page {
    margin: auto;
}

.cardPage {
    .cards-header {
        .quick-actions {
            display: flex;
            vertical-align: middle;
            text-align: center;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: flex-end;
            margin-bottom: 2rem;

            .MuiButton-root.MuiButton-contained {
                border-radius: 50px;
                font-weight: 500;
                letter-spacing: 0;
                margin: 0 0 0 0.5rem;
                padding: 8px 20px;
                text-transform: initial !important;
                width: 220px;
            }
        }
    }
    h4 {
        font-size: 1rem;
        margin: 0 0 0.6rem 0.5rem;
    }
    .cardsContainer {
        display: flex;
        
        .cardListContainer {
            margin: 0 2rem 2rem 0;
        }
        .cardDetailsContainer {
            width: 100%;
        }
    }
    .cardItem {
        padding: 1rem; 
        font-size: 0.84rem;
        margin-bottom: 0.5rem;
        opacity: 0.7;
        transition: opacity 0.3s;
        min-width: 270px;
    
        .cardHeader, .cardDetails {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
        .cardHeader {
            margin-bottom: 0.8rem;
        }
    
        .cardBalance {
            font-size: 1.2rem;
            font-weight: 600;
            padding: 0 0 0.4rem 0;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            flex-wrap: wrap;
            line-height: 1.3rem;
            margin-bottom: 1.2rem;
    
            .bal-title {
                font-size: 0.75rem;
                font-weight: normal;
                color: #555555;
            }
        }
    
        p {
            margin-bottom: 0.3rem;
        }
    
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
        
        &.cardElement {
            background: #e1e1e1;
        }
        
        &.cardElementSelected {
            background: #bdbdbd;
            opacity: 1;
        }
        .cardStatus {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: 0.8rem;
            align-content: center;
            align-items: center;
            line-height: initial;
            margin: 0;
        }
    }
    .cardStatus, .cardSetting {     
        color: rgba(0, 0, 0, 0.5);

        &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    
        &.status-ACTIVE {
            &:before {
                background-color: #0ca133;
                border: 3px solid #93e7a1;
            }
        }
        &.status-ACTIVEOFF,
        &.status-ORDERED {
            &:before {
                background-color: #81d6f8;
                border: 3px solid #94cef0;
            }
        }
        &.status-OTHER  {
            &:before {
                background-color: #888a8b;
                border: 3px solid #d3d9dd;
            }
        }
        &.status-CLOSED,
        &.status-LOST,
        &.status-STOLEN,
        &.status-DAMAGED,
        &.status-RESTRICTED {
            &:before {
                background-color: #e42e2e;
                border: 3px solid #f8bbbb;
            }
        }
    }    
    .cardSetting {
        &:before {
            width: 10px;
            height: 10px;
        }
        &.status-ACTIVE, &.status-ACTIVEOFF, &.status-ORDERED,
        &.status-OTHER, &.status-CLOSED, &.status-LOST,
        &.status-STOLEN, &.status-DAMAGED, &.status-RESTRICTED {
            &:before {
                border-width: 2px;
            }
        }
    }    
    .MuiList-root {
        &.cardOptions {
            li {
                .contactSupport {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    line-height: 1.4rem;
                    margin: 0;
                }
                .emailSupport {
                    margin-right: 1.5rem;
                }
                &.cardClosed {
                    background-color: #fdeded;
                    color: #5f2120;
                }
            }
        }
    }
}


@media only screen and (max-width: $mobileBreak) {
    .cardPage {
        .cardsContainer {
            display: grid;
            
            .cardListContainer {
                margin: 0 0 1rem 0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                overflow-x: scroll;

                .cardItem {
                    margin-right: 0.5rem;
                }

            }
            .cardDetailsContainer {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .cardPage {
        .cards-header {
            .MuiGrid-item {
                flex-basis: 100%;
                max-width: 100%;

                .list-header {
                    margin-bottom: 0;
                }

                .quick-actions {
                    justify-content: center;
                }
            }
        }
    }
}