.MuiPaper-root.MuiAppBar-root.appbar,
.MuiPaper-root.MuiAppBar-root.appbar-legal {
    color: $brandTextColour;

    .MuiButtonBase-root-MuiIconButton-root, .MuiIconButton-root {
        path {
            color: $brandColourPrimary !important;
        }
    }
}

.login-page {
    header {
        .logo-link {
            .logo {
                max-width: 16rem;
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .login-page {
        header {
            .logo-link {
                .logo {
                    max-width: 12rem;
                }
            }
        }
    }
    .MuiPaper-root {
        .logo-link {
            .logo {
                max-height: 2.8rem;
            }
        }
    }
}