.account-metric-head {
    .metric-group {
        margin: 0 0 3rem 0;

        .metric-tile {
            background-color: #e9e9e9 !important;
            border-style: solid;
            border-color: white;
            border-width: 1px;
            flex-basis: auto;
            min-height: 60px;
            padding: 5px;
            color: $light;
            width: 33%;
        }
        .metric-inner-tile {
            height: 100%;
            @include make-row();
            margin: 0!important;
        }
        .metric-label {
            color: #333333;
            font-size: 1.1rem;
            padding-left: 5px;
        }
        .metric-value {
            color: #333333;
            font-size: 1.3rem;
            position: absolute;
            right: 1em;
            bottom: 2px;
        }
    }
}

.account-summary-container {
    margin: 0 0 1rem 0;
}

@media only screen and (max-width: 768px ) {
    .account-metric-head {
        .metric-group {
            .metric-tile {
                width: 100%;
            }
        }
    }
}