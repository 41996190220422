// IMPORT GOOGLE FONT
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;800&family=Montserrat:wght@300;400;500;600&display=swap');
$primary-font: 'Inter', sans-serif !important;

.login-page {
    height: auto;
    align-items: stretch;
    padding: 0;

    header {
        display: flex;
        width: 100%;
        height: 25%;
        align-items: end;
        justify-content: center;
    
        .logo-link .logo {
            max-width: 20rem;
            max-height: 10rem;
            width: 100%;
            margin-bottom: 3.2rem;
        }
        .login-screen-header-one, .login-screen-header-three {
            display: block;
            text-align: center;
        }
        .login-screen-header-three {
            .logo-link {
                display: block;
            }
        }
    }

    .login-wrapper-inner {
        width: 100%;
        max-width: 560px;
        padding: 1rem;
    }
    
    .login-screen {
        background-color: #fff;
        color: $brandTextColour;
    
        .login-header {
            text-align: center;
    
            h1 {
                font-size: 2.5rem;
                font-weight: 700;
                margin-bottom: 2rem;
                text-transform: uppercase;
            }
            
            p {
                margin: 0 0 2rem 0;
            }
        }
        &.set-password {
            .login-header h1 {
                font-size: 2rem;
            }
        }
        .input-link {
            background-color: transparent;
            color: lighten($brandTextColour, 30%);
            font-family: $primary-font;
            font-size: 0.7rem;
            font-weight: 600;
            margin-left: 0.3rem;
            padding: 0;
            text-transform: uppercase;
        
            &:hover {
                background-color: transparent;
                color: $brandColourPrimary;
                text-decoration: none;
            }
        }
        .prelogin-form {
            label {
                color: lighten($brandTextColour, 20%);
                font-size: 0.85rem;
                font-weight: 500;
                letter-spacing: 0.02rem;
                line-height: 1.6rem;
                text-transform: uppercase;
                margin: 0 0 0.2rem 0.5rem;
            }
            .form-control {
                background-color:#fff;
                border: 0;
                border-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                box-shadow: 0 0 30px 6px rgba(0,0,0,0.1);
                -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-family: $primary-font;
                margin-bottom: 0.2rem;
                padding: 1.6rem 1.2em;
            }
            .invalid-feedback {
                margin: 0.25rem 0.5rem;
            }
            .button-row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .legal-details {
        color: lighten($brandTextColour, 30%);
        margin-top: 3rem;
        text-align: center;
    
        .button-container {
            display: inline-block;
        }

        .MuiButton-root {
            background-color: transparent;
            color: lighten($brandTextColour, 30%);
            font-family: $primary-font;
            font-size: 0.8rem;
            font-weight: 400;
            text-decoration: none;
            text-transform: none;
    
            &:hover {
                background-color: transparent;
                color: $brandColourPrimary;
            }
        }
    }
    .MuiButton-root.MuiButton-contained {
        background-color: $buttonBackgroundColour;
        color: $buttonTextColour;
        font-family: $primary-font;
        font-size: 1rem;
        padding: 0.7rem;

        .MuiSvgIcon-root {
            height: 1.2rem;
            width: 1.2rem;
        }

        &:hover {
            background-color: darken($buttonBackgroundColour, 5%);
            color: $buttonTextColour;
        }
    }
}

.login-page.login-screen-backgroundhero,
.login-page.login-screen-default {
    width: 100%;
    padding-bottom: 30px;

    .login-wrapper {
        display: flex;
        width: 100%;
        height: 70%;
        align-items: start;
        justify-content: center;
    }
}

.login-screen-backgroundhero {
    background-size: cover;
    z-index: 0;

    &:before {
        background-color: rgba($primaryBackground, 0.4);
        content: '';
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: -1;
    }

    .login-screen {
        background-color: rgba(255, 255, 255, 0.85);
    }

    .legal-details {
        color: lighten($brandTextColour, 100%);
    
        .MuiButton-root {
            color: lighten($brandTextColour, 100%);
            text-decoration: none;
    
            &:hover {
                color: lighten($brandTextColour, 60%);
            }
        }
    }
}
.login-screen-splitHero {
    display: flex;
    width: 100%;
    overflow: hidden;

    .login-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

        header {
            height: auto;
            margin: 2.5rem 0 2.5rem 9.4rem;
            justify-content: flex-start;
    
            .logo-link .logo {
                margin-bottom: 0;
            }
        }

        .login-wrapper-inner {
            align-items: center;
            flex: 1 1;
            display: flex;
            justify-content: center;
        }

        .login-screen {
            box-shadow: none;
            padding: 2rem 3.8rem;

            .login-header {
                text-align: left;
            }
        }
    }

    .login-hero-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow:hidden;
        transform-origin: bottom left;
        -ms-transform: skew(-10deg, 0deg);
        -webkit-transform: skew(-10deg, 0deg);
        transform: skew(-10deg, 0deg);

        .login-hero-img {
            background-position: center;
            background-size: cover;
            height: 100%;
            transform-origin: bottom left;
            -ms-transform: skew(10deg, 0deg);
            -webkit-transform: skew(10deg, 0deg);
            transform: skew(10deg, 0deg);

            &:before {
                background-color: rgba($primaryBackground, 0.2);
                content: '';
                position: absolute;
                height: 100vh;
                width: 100%;
                z-index: -1;
            }
        } 
    }
}
.login-screen-default {
    .background-fill {
        background: $primaryBackground;
        display: block;
        height: 430px;
        position: absolute;
        width: 100%;
        z-index: -1;
    }
}

.dialog-details-container {
    text-align: center;
    padding: 2rem;

    h2 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    a {
        color: $brandColourPrimary;
    }
    form {
        width: 70%;
        margin: 2.5rem auto 0;
    }
}

.reset-password-container {
    padding: 2.5rem 2rem;

    h2 {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    form {
        margin: 2.5rem auto 0;
    }
}
.MuiBox-root,
.login-page .login-screen,
.dialog-details-container,
.reset-password-container {
    .MuiFilledInput-root {
        background-color:#fff;
        border-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 0 30px 6px rgba(0,0,0,0.1);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: $primary-font;
        margin-bottom: 0.63rem;

        &:before {
            content: none;
        }
        &:after {
            border-bottom: 2px solid $brandColourPrimary;
        }
        &:hover {
            background-color:#fff;
        }

        &.Mui-focused {
            background-color: #fff;
        }
    }
    .MuiFilledInput-input {
        color: $brandTextColour;
        font-weight: 500;
        font-size: 0.9rem;
        height: auto;
    }
    .MuiTextField-root {
        width: 100%;
    }
    .icn-password-visibility {
        margin-right: 0.3rem;

        .MuiSvgIcon-root {
            color: lighten($brandTextColour, 35%);
            font-size: 1.2rem;

            &:hover {
                color: $brandColourPrimary;
            }
        }
    }
    .MuiFormControl-root label {
        color: lighten($brandTextColour, 20%);
        font-family: $primary-font;
        font-size: 0.9rem;
        font-weight: 500;
        letter-spacing: 0.02rem;
        line-height: 1.6rem;
        margin: 0;
        text-transform: uppercase;
    }
    .MuiInputLabel-root.Mui-focused {
        color: $brandColourPrimary;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.9rem;
    }
    .Mui-focused fieldset {
        border-color: $brandColourPrimary;
    }
}

.terms-conditions-dialog, .privacy-policy-dialog {
    .appbar-legal {
        background-color: $brandColourPrimary;
    }
    .content {
        color: $brandTextColour;
        width: 100%;
        max-width: 1140px;
        padding: 2.5rem;
        margin: 3.5rem auto;

        h1 {
            color: darken($brandTextColour, 15%);
            font-size: 2rem;
            margin: 0.6rem 0 1.4rem 0;
        }
        h2 {
            color: $brandColourPrimary;
            font-size: 1.2rem;
            font-weight: 600;
            margin: 2rem 0 0.6rem 0;
        }
    }
}
.logout-message {
    text-align:center;
}


@media only screen and (max-width: 980px) {
    .login-screen-splitHero {
        flex-direction: column-reverse;

        .login-wrapper {
            padding-bottom: 1.4rem;

            header {
                height: auto;
                margin: 0;
                justify-content: center;
            }
            .login-screen {
                .login-header {
                    text-align: center;
                }
            }
        }
        .login-hero-wrapper {
            transform: skew(0deg,-10deg);

            .login-hero-img {
                transform: skew(0deg,10deg);
            }
        }
    }
    .dialog-details-container {
        h2 {
            font-size: 1.8rem;
        }
        form {
            width: 100%;
            margin: 2.6rem auto 0;
        }
    }
    .login-wrapper {
        align-items: initial;
        height: auto;
    }
    .login-page {
        .login-wrapper {
            header {
                .logo-link .logo {
                    margin: 2rem 0 0 0;
                }
            }
        }
    }
    .terms-conditions-dialog, .privacy-policy-dialog {
        .content {    
            h1 {
                font-size: 1.9rem;
                margin: 0 0 1.2rem 0;
            }
            h2 {
                font-size: 1.2rem;
            }
        }
    }
}
@media only screen and (max-width: $mobileBreak) {
    .login-page {
        flex-wrap: wrap;

        .login-wrapper {
            header {
                height: 18%;
    
                .logo-link .logo {
                    margin: 1.4rem 0 1rem 0;
                }
            }
            .login-screen {
                .login-header h1 {
                    display: none;
                }
            }
        }
        &.login-screen-splitHero {
            .login-wrapper {
                .login-screen {
                    padding: 0;
                }
            }
        }
        &.login-screen-backgroundhero,
        &.login-screen-default {
            .login-wrapper {
                .login-screen {
                    padding: 2rem 1rem;
                }
            }
        }
    }
}
@media screen and (max-height: 750px ){
    .login-screen-splitHero {
        .login-hero-wrapper {
            display: none;

            .login-hero-img {
                transform: skew(0deg,10deg);
            }
        }
    }
    .login-page {
        .login-wrapper {
            header {
                .logo-link .logo {
                    margin: 2rem 0 0 0;
                }
            }
        }
    }
    .terms-conditions-dialog, .privacy-policy-dialog {
        .content {    
            h1 {
                font-size: 1.9rem;
                margin: 0 0 1.2rem 0;
            }
            h2 {
                font-size: 1.2rem;
            }
        }
    }
}