
.navbar {
    .nav-link {
        padding: 0.5rem 1rem;
    }
    .sub-wrap {
        padding: 1rem 0;
        z-index: 1000;
        @if $fullWidth {
            max-width: unset;

            .dropdown-menu {
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-top: none;
                border-radius: 0 0 0.25rem 0.25rem;

                .dropdown-item {
                    &:focus {
                        background: $dropdown-link-hover-bg;
                        color: $dropdown-link-color;
                    }
                }
            }
        }
        @else {
            .dropdown-menu {
                border-top: none;
                border-radius: 0;
            }
        }
    }
    height: 4.25 * $spacer;
}
.bottom-quick-nav {
    .MuiBottomNavigationAction-root {
        &.Mui-selected {
            color: $brandColourPrimary;
        }
        .MuiBottomNavigationAction-label.Mui-selected {
            font-size: 0.77rem;
        }
        .MuiSvgIcon-root {
            font-size: 1.2rem;
        }
    }

}
.nav-drawer, .profile-drawer {
    a {
        text-decoration: none;
    }
    .nav-drawer-container,
    .profile-drawer-container {
        flex: 1;
        justify-content: space-between;
    }
}
.MuiDrawer-docked .MuiDrawer-paper {
    border: none;
    box-shadow: 0 0 10px 6px rgba(0,0,0,0.1);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1190;

    .MuiListItem-root, .MuiListItemButton-root {
        padding: 10px 13px 10px 24px;
    }
}
.nav-drawer {
    .MuiList-root {
        padding: 0;
        
        .drawer-subnav {
            .MuiListItem-root {
                padding: 5px 13px 5px 24px;

                .MuiListItemText-secondary {
                    font-size: 0.8rem;
                }
            }
        }
    }
    a {
        text-decoration: none;
        display: block;

        .MuiListItemIcon-root {
            color: lighten($brandTextColour, 45);
            transition: all 300ms;

            .MuiSvgIcon-root {
                font-size: 1.3rem;
            }
        }

        &:hover {
            background-color: lighten($accentHoverColour, 1);
            color: $brandColourPrimary;

            .MuiListItemIcon-root {
                color: $brandColourPrimary;
                transition: all 300ms;
            }
        }
    }
    .nav-help {
        .MuiListItemIcon-root, .MuiSvgIcon-root {
            color: lighten($brandTextColour, 45);
            font-size: 1.3rem;
            transition: all 300ms;
        }

        .MuiListItemButton-root {
            &:hover {
                background-color: lighten($accentHoverColour, 1);
                color: $brandColourPrimary;
            
                .MuiListItemIcon-root, .MuiSvgIcon-root {
                    color: $brandColourPrimary;
                    transition: all 300ms;
                }
            }
        }
    }
    .MuiPaper-root .MuiTypography-body1 {
        font-size: 0.84rem !important;
    }
    .MuiTypography-body2 {
        color: $brandTextColour;
    }
    .active{
        background-color: #ededed;
        color: $brandColourPrimary;

        .MuiListItemIcon-root {
            color: $brandColourPrimary;
        }
    }
    .drawer-subnav {
        .MuiCollapse-entered {
            background-color: #f5f5f5;

            .active{
                background-color: #ededed;
                color: $brandColourPrimary;
        
                .MuiListItemIcon-root {
                    color: $brandColourPrimary;
                }
            }
        }
    }
}
.profile-drawer {
    h2 {
        color: $brandColourPrimary;
        font-size: 1.5rem;
        padding: 0.5rem 1.2rem
    }
    h3 {
        font-size: 1rem;
    }
    .profile-card {
        box-shadow: none;
        padding: 0.2rem 1.5rem;
        margin: 0;

        .MuiListItem-root {
            padding: 0.5rem 2rem;
            margin: 0;
        }
        .MuiListItemIcon-root {
            min-width: 40px;
        }
        .MuiCardContent-root:last-child {
            padding-bottom: 1rem;
        }
        .MuiSvgIcon-root {
            color: $brandColourSecondary;
            width: 1.3rem;
        }
        h4, .MuiTypography-body1 {
            color: darken($brandTextColour, 30);
            letter-spacing: .01rem;
            font-size: 0.86rem;
            font-weight: 600;
        }
        h5, .MuiTypography-body2 {
            color: $brandTextColour;
            font-size: 0.82rem;
            letter-spacing: 0.02rem;
        }
    }
    .contact-support {
        p {
            font-size: 0.85rem;
            margin-bottom: 0.5rem;
        }
        a {
            color: $brandColourPrimary;
        }
    }
}
.appbar {
    a {
        .MuiBadge-badge {
            &:hover {
                color: #fff!important
            }
        }
        
    }
}
.notifications-container {
    .MuiListItem-root {
        border-bottom: 1px solid lighten($brandTextColour, 70);
    }
    .MuiTypography-subtitle1 {
        color: lighten($brandTextColour, 50);
        font-size: 0.73rem;
        font-style: italic;
        line-height: 1rem;
    }
    h2 {
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 1.2rem;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .MuiTypography-body1 {
        font-size: 0.8rem;
        line-height: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    .notifications-urgent {
        h2, .MuiListItemIcon-root {
            color: #c12525;
        }
    }
    .notifications-task {
        h2, .MuiListItemIcon-root {
            color: #22d9b7;
        }
    }
    .notifications-alert {
        h2, .MuiListItemIcon-root {
            color: $brandColourPrimary;
        }
    }
}

.bg-primary {
    background-color: $primary !important;
}

@media only screen and (max-width: 959px) {
    .nav-drawer {
        .MuiList-root {
            .drawer-subnav {
                .MuiListItem-root {
                    padding: 3px 16px 3px 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .navbar {
        padding: 0 1rem;
        .nav-link {
            padding: 20px 25px !important;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 599px) {
    .MuiDrawer-docked .MuiDrawer-paper {
        .MuiListItem-root, .MuiListItemButton-root {
            padding: 8px 8px 8px 15px;
        }
    }
}