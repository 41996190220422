.statements-list {
    .list-header {
        margin-bottom: 1.4rem;
        display: flex;
        align-items: baseline;

        h2 {
            margin-bottom: 0;
        }
    }
}
@media only screen and (max-width: 450px) {
    .MuiLink-root {
        color: $brandColourPrimary !important;
    }
}