.form-control {
    font-size: 0.9rem;
    padding: 1.4rem 0.8em;
}
.formnote {
    margin-left: $spacer;
}

.form-error-message {
    margin-left: $spacer;
}

.sub-wrap.form-wrap {
    max-width: 800px;
}

.react-datepicker-wrapper {
    // max-width: 15 * $spacer;
}

.form-control-currency {
    // max-width: 11 * $spacer;
}

.bpayaddbiller-link {
    margin-top: -0.75 * $spacer;
    margin-bottom: $spacer;
}

.transfer-schedule-until .form-control-wholenumber {
    max-width: 13 * $spacer;
}
.btn {
    padding: 0.7rem 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}
.btn-submit {
    float: right;
    min-width: 5 * $spacer;
}

.button-group-label {
    display: block;
}

.button-group-btn-item {
    $background: #fff;
    $border: #1d1d1d;
    @include button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) // @include button-variant(#fff, #1d1d1d, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))
}

.button-group{
    display: flex;
    justify-content: space-between;
}

.react-datepicker-popper {
    z-index: 100 !important;
}

.form-wrap>div {
    width: 100%;
}

.form-group {
    margin-bottom: 0;
}