.transfer-card {
    min-height: 12rem;
}

.amount-value {
    font-size: x-large;
    margin-bottom: -0.5 * $spacer;
    margin-top: 0.5rem;
}

#SaveBillerDetails {
    margin-right: 10px;
}
.action-card-text {
    margin-bottom: 0.5rem;
}

// Transfer funds transfer tables (history + pending)
.payment-details {
    h6 {
        color:#b5b5b5;
        font-family: $primary-font;
    }
    .daily-limits-info {
        text-align: right;

        h4 {
            font-size: 0.84rem;
            font-weight: normal;
            color: #6c757d !important;
            margin: 2.2rem 0 0.1rem;

            &.remaining-limit-title {
                margin-right: 2.3rem;
            }
        }
        .daily-limit-amount {
            font-size: 1.1rem;
            margin: 0;
        }
        .edit-limits {
            margin-left: 0.5rem;
            text-align: left;

            svg {
                color: $brandColourPrimary;
                font-size: 1.2rem;
            }
        }
    }
    .btn-group {
        width: 100%;

        .button-group-btn-item {
            border: 1px solid rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.54);
            padding: 0.82rem 0.8rem;

            &.active, &:focus {
                border-color: rgba(65, 65, 65, 0.12) !important;
                color: $brandTextColour !important;
            }
        }
    }
}
.date-header {
    width: 15%;
}

.receipt-header {
    width: 15%;
}

.frequency-header {
    width: 20%;
}

.type-header {
    width: 30%;
}

.amount-header {
    width: 10%;
}

.action-header {
    width: 1%;
}

.bpay-image {
    float: left;
    margin-right: 0.5 * $spacer;
    margin-bottom: 0.6rem;
    width: 2 * $spacer;
}

.list-group-item-value {
    float: right;
}

.confirm-item {
    background-color: #fcfcfc;
}

.confirm-item-header {
    font-size: larger;
}

.list-group-confirm {
    margin-bottom: 2 * $spacer;
}

.icon-confirm {
    margin-right: $spacer;
}

.transfer-card-disabled {
    background-color: #ebebeb;
}

// BPAY
.select-destination>.card-header {
    font-weight: bold;
}

.select-destination>.list-group {
    cursor: pointer;
     :hover {
        background-color: #f8f8f8;
    }
}

.select-new li {
    display: flex;
    justify-content: space-between;
}

// Payment summary cards

.payment-create-summary-account-details-number {
    color: $gray-600;
}

.payment-create-summary-account-details-available,
.payment-create-summary-account-details-balance {
    display: flex;
    justify-content: space-between;
}

.card-body.selectable-card,
.card-body.payment-create-summary-account {
    cursor: pointer;
}

.payment-create-summary {
    margin-bottom: 1*$spacer;
}

.payment-create-summary .list-group-item {
    padding: 0;
}

.payment-create-summary .card-body {
    padding: 0.75rem 1.25rem;
}

.payment-create-summary h5 {
    margin-bottom: 0.25*$spacer;
}

.payment-create-summary-account-details {
    margin-bottom: 0.5rem;
}

.payment-create-summary-account-warning svg {
    color: $orange;
}


/** TRANSFERS & PAYMENTS **/
.MuiAutocomplete-listbox {
    font-size: 0.9rem;

    .MuiListSubheader-root.MuiAutocomplete-groupLabel {
        color: #333333;
        font-family: $primary-font;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: #ebebeb;
    }
    .MuiAutocomplete-groupUl {
        li {
            display: flex;
            justify-content: space-between;
            padding: .5rem 1rem;
            font-size: 0.82rem;

            .account-icon {
                img {
                    margin: 0 0.9rem 0 0.2rem;
                }
                .MuiSvgIcon-root {
                    background-color: #E9E9E9;
                    border-radius: 50px;
                    color: #9B9B9B;
                    padding: 0 5px;
                    margin: 0 2.5rem 0 1.5rem;
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }
            .account-title {
                .title {
                    font-weight: 600;
                    margin: 0 2rem 0 0;
                }
                .details {
                    display: none;
                }
            }
            .account-bsb {
                margin: 0 1.5rem;
            }
            .account-num {
                margin: 0 1.5rem;
            }
            .account-balance {
                margin-left: auto;

                b {
                    font-weight: 600;
                }
            }
        }
    }
}

.dd-transfer-accounts {
    justify-content: center;

    .redraw-balance {
        font-size: 0.9rem;
        position: absolute;
        right: 0;
        margin-right: 4.5rem;

        b {
            font-weight: 600;
        }
    }
}

//MANAGE PAYEES
.payees-list-mobile {
    li {
        text-align: center;

        .subtitle {
            font-weight: 600;
        }
    }
    
    .delete-item {
        text-align: right;
        position: absolute;
        top: -10px;
        right: -5px;
        z-index: 1;
    }
}

//DELETE DIALOG
.delete-payment-account {
    .MuiDialog-paper {
        overflow: visible;
        border-top: 5px solid #aa1917;

        .MuiDialogTitle-root {
            text-align: center;

            h5 {
                font-weight: 600;
            }
            .MuiSvgIcon-root {
                color: #fff;
                background: #aa1917;
                border-radius: 50%;
                padding: 7px;
                margin-top: -4.5rem;
                font-size: 4rem;
                animation: pulse-red 2s infinite;
            }
        }
        .MuiDialogContent-root {
            ul {
                li {
                    text-align: center;

                    .subtitle {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
//CONFIRM TRANSFER/PAYMENT DIALOG
//SUCCESSFUL TRANSFER/PAYMENT DIALOG
.confirm-transfer-payment,
.success-transfer-payment,
.success-requested-disbursement,
.cancel-transfer-payment {
    .MuiDialog-paper {
        overflow: visible;
        border-top: 5px solid #2d9fd9;

        .MuiDialogTitle-root {
            text-align: center;

            h5 {
                font-size: 1.7rem;
                font-weight: 600;
                margin-bottom: 0;
            }
            .MuiSvgIcon-root {
                color: #fff;
                background: #2d9fd9;
                border-radius: 50%;
                padding: 10px;
                margin-top: -4.5rem;
                font-size: 3.8rem;
                transform: scale(5);
                animation: pulse-blue 2s infinite;
            }
        }
        .MuiDialogContent-root {
            p {
                font-size: 0.95rem;
            }
            ul {
                li {
                    text-align: center;

                    .subtitle {
                        font-weight: 600;
                        margin-right: 2rem;
                    }
                }
            }
            .transfer-amount {
                margin: 1.5rem 0;
                font-size: 1.9rem;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                align-items: center;
        
                .caption {
                    font-size: 0.75rem;
                    color: #bfbfbf;
                    margin: 0;
                    line-height: 1rem;
                }
            }
            .transfer-details {
                background: #f1f1f1;
                border-radius: 1rem;
                padding: 1rem 1.5rem;
                margin: 1rem auto 2rem;
                width: 85%;
                min-width: 400px;
        
                li {
                    border-bottom: 1px solid #e5e5e5;
                    padding: 3px 6px;

                    &:last-of-type {
                        border: none;
                    }
        
                    p {
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.95rem;
                        text-align: right;
                    }
                }
            }
        }
    }
}
.confirm-transfer-payment {
    .MuiButton-root.MuiButton-contained[type=submit] {
        background-color: #2d9fd9;
    }
}

//SUCCESSFUL TRANSFER/PAYMENT DIALOG
.success-transfer-payment,
.success-requested-disbursement {
    .MuiDialog-paper {
        border-top: 5px solid #5cb660;

        .MuiDialogTitle-root {
            .MuiSvgIcon-root {
                color: #fff;
                background: #5cb660;
                border-radius: 50%;
                padding: 12px;
                margin-top: -4.5rem;
                font-size: 4rem;
                animation: pulse-green 2s infinite;
            }
        }
        .MuiDialogContent-root {
            .transfer-amount {
                margin: 0 0 1.5rem 0;
                font-size: 2.2rem;
                font-weight: 500;
        
                .caption {
                    color: $brandTextColour;
                    font-size: 1.2rem;
                    margin: 0.5rem 0 0;
                    width: 85%;
                    line-height: 1.8rem;
                    font-weight: 500;
                }
                .sub-caption {
                    font-size: 0.85rem;
                    color: #bfbfbf;
                    margin-top: 0.5rem;
                    line-height: 1.2rem;
                }
            }
            .transfer-details {
                background: #edf7ed;

                li {
                    border-bottom: 1px solid #cfe5d1;

                    p {
                        color: #527354;
                    }
                }
            }
            .MuiButton-root.MuiButton-contained {
                background-color: #5cb660;
            }
        }
    }
}
//CANCEL TRANSFER/PAYMENT DIALOG
.cancel-transfer-payment {
    .MuiDialog-paper {
        border-top: 5px solid #aa1917;

        .MuiDialogTitle-root {
            .MuiSvgIcon-root {
                color: #fff;
                background: #aa1917;
                border-radius: 50%;
                padding: 12px;
                margin-top: -4.5rem;
                font-size: 4rem;
                animation: pulse-red 2s infinite;
            }
        }
        .MuiDialogContent-root {
            .transfer-amount {
                margin: 0 0 1.5rem 0;
                font-size: 2.2rem;
                font-weight: 500;
        
                .caption {
                    color: $brandTextColour;
                    font-size: 1.2rem;
                    margin: 0.5rem 0 0;
                    width: 85%;
                    line-height: 1.8rem;
                    font-weight: 500;
                }
                .sub-caption {
                    font-size: 0.85rem;
                    color: #bfbfbf;
                    margin-top: 0.5rem;
                    line-height: 1.2rem;
                }
            }
            .transfer-details {
                background: #fdeded;

                li {
                    border-bottom: 1px solid #f1e2e2;

                    p {
                        color: #5f2120;
                    }
                }
            }
            .MuiButton-root.MuiButton-contained[type=submit] {
                background-color: #aa1917;
            }
        }
    }
}

//DISBURSEMENTS
.success-requested-disbursement {
    h3 {
        font-size: 1rem;
        margin: 1.8rem 0 0.8rem 0;    
    }
    .MuiDialog-paper {
        border-top: 5px solid #5cb660;

        .MuiDialogTitle-root {
            .MuiSvgIcon-root {
                color: #fff;
                background: #5cb660;
                border-radius: 50%;
                padding: 12px;
                margin-top: -4.5rem;
                font-size: 4rem;
                animation: pulse-green 2s infinite;
            }
        }
        .MuiDialogContent-root {
            .transfer-details {
                width: 100%;

                .supporting-docs {
                    p {
                        text-align: left;
                        
                        .subtitle {
                            margin-bottom: 0.2rem;
                        }
                    }
                }
            }
            .MuiButton-root.MuiButton-contained {
                background-color: #5cb660;
            }
        }
    }
}
.disbursement-details {
    .helper-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        margin: 0.4rem 0.8rem;
    }
    .upload-wrap {
        padding: 30px 0px;
        border: 2px dashed #ced4da;
        background: #f1f1f1;
        position: relative;

        &:hover {
            background: #f5f5f5;
        }

        .file-upload-input {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
            cursor: pointer;
        }

        .drag-text {
            text-align: center;
    
            h3 {
                font-size: 1.2rem;
                margin: 0 0 0.2rem 0;
            }
            p {
                color: #a9a9a9;
                font-size: 0.9rem;
                margin: 0;
            }
        }
    }
    .file-upload-details {
        margin-top: 3.5rem;
        display: flex;
        align-items: center;

        .img-thumb {
            padding: 0;
            border: none;
            margin-right: 0.5rem;
            border-radius: 0.25rem;
            max-width: 100%;
        }
        .file-filetype {
            background-color:#f1f1f1; 
            margin-right: 0.5rem;
            border-radius: 0.25rem;
            height: 60px;
            width: 60px;
            text-transform: uppercase;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 500;
        }
        .file-details {
            margin-left: 0.3rem;

            p {
                font-weight: 500;
                margin-bottom: 0.2rem;
                font-size: 0.95rem;
            }
            span {
                color: #a1a1a1;
                margin-left: 1.2rem;
                font-size: 0.9rem;
                font-weight: 400;
            }
        }
    }
}

@keyframes pulse-blue {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
	}
}
@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(170, 25, 23, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
}
@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(92, 182, 96, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
}

@media only screen and (max-width: 950px) {
    .payment-details, .disbursement-details {
        .MuiGrid-root {
            flex-basis: 100%;
            max-width: 100%;
        }

        .file-upload-details {
            margin: 1rem 0;
        }

        .daily-limits-info {
            text-align: left;
            flex-wrap: nowrap;
            justify-content: space-between;

            h4 {
                margin: 0 0 0.2rem 0; 
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .biller-container {
        height: 100% !important;
    }
    .confirm-transfer-payment,
    .success-transfer-payment,
    .success-requested-disbursement,
    .cancel-transfer-payment,
    .delete-payment-account {
        .MuiDialog-paper {
            .MuiDialogTitle-root {
                .MuiSvgIcon-root {
                    color: #fff;
                    border-radius: 50%;
                    padding: 10px;
                    margin: 1rem 0 2rem;
                    font-size: 3.8rem;
                }
            }
            .MuiDialogContent-root {
                margin: 0.5rem 1.5rem;
    
                p {
                    font-size: 0.9rem;
                }
                .transfer-amount {
                    font-size: 1.5rem !important;
                }

                .transfer-details {
                    padding: 0.5rem 1rem;
                    width: 100%;
                    min-width: unset;
    
                    li p {
                        justify-content: center;
                        font-size: 0.95rem;
                        flex-direction: row;
                        flex-wrap: wrap;
                        text-align: center;
    
                        span {
                            margin: 0;
                            width: 100%;
                        }
                    }
                    .supporting-docs {
                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .dd-transfer-accounts {
        .redraw-balance {
            display: none;
        }
    }
    .MuiAutocomplete-listbox {
        .MuiAutocomplete-groupUl {
            li {
                flex-direction: column;
                border-bottom: 1px solid #f1e2e2;
                padding: 1rem;

                &:last-of-type {
                    border-bottom: none;
                }

                .account-icon {
                    display: none;
                }
                .account-title {
                    width: 100%;

                    .title {
                        font-weight: 600;
                        margin: 0 2rem 0 0;

                        .bpay-flag {
                            color: #979797;
                            background-color: #EFEFEF;
                            margin: 0 0.5rem;
                            padding: 0.2rem 0.4rem;
                            font-size: 0.6rem;
                            border-radius: 50px;
                        }
                    }
                    .details {
                        display: block;
                    }
                }
                .account-bsb, .account-num {
                    display: none;
                }
            }
        }
    }
    .btn-group-toggle {
        .button-group-btn-item {
            font-size: 0.7rem;
        }
    }
    .payment-details {
        .daily-limits-info {
            flex-wrap: wrap;
        }
    }
}