$brandColourPrimary: #ec9043;  // Brand Primary Colour
$brandColourSecondary: #54595d; // Brand Secondary Colour
$brandTextColour: #3d3d3d;  // Brand Text Colour

$primaryBackground: #ffffff; // This could be your primary brand colour. This will be used on the default login screen and top bar background when logged in.
$accentHoverColour: #f3f3f3; // Default: #f3f3f3 - This should be a light / neutral colour, if no colour use Default.

$buttonBackgroundColour: #ec9043;  // This could be your primary or secondary brand colour.
$buttonTextColour: #fff;  // This should be a contrasting colour against the $buttonBackgroundColour.

$spinnercolour: $brandColourPrimary;  // This is a loading spinner which is used in various parts of the Fortis. It will be use the Brand Primary Colour unless otherwise updated.

