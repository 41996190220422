.limits-container {
    .list-limits {
        margin-bottom: 1.5rem;

        .display-limits {
            text-align: left;
            font-family: $primary-font;

            h4 {
                color: $brandColourPrimary;
                font-size: 1.2rem;
                margin: 0 0 1.2rem 0;
            }
            .limits {       
                .limit {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-content: center;
                    margin-bottom: 1rem;
            
                    .money {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .effective-alert {
        margin-bottom: 1.5rem;
    }
}



@media only screen and (max-width: $mobileBreak) {
    .list-limits {
        .MuiGrid-root {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}
