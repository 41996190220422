.dashboard-page {
    .transactions-list {
        .list-header {
            padding-left: 0.5rem;
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .content-wrapper {
        .transactions-list {
            &.sub-wrap {
                padding: 0;
            }
            .filterContainer {
                padding: 0 1rem;

                .filterControl {
                    width: 100%;
                }
            }
        }
    }
    
    .mobile-transaction-list-container {
        .card {
            border: 0.01px solid #fff;
            border-radius: 0;

            .card-header {
                color: $brandColourPrimary;
                border-bottom: none;
                font-weight: 500;
                font-size: 0.9rem;
                padding: 0.5rem 1.1rem;
            }
            .list-group-item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.03);

                &:last-child {
                    border-bottom: none;
                }

                p {
                    margin: 0;
                    font-size: 0.9rem;
                }
                .text-muted {
                    font-size: 0.8rem;
                }
                .money {
                    span {
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                }
            }
        }
        .dataEnd {
            text-align: center;
            padding-top: 1rem;
            font-size: 0.85rem;
        }
    }
}

@media only screen and (max-width: 550px) {
    .MuiTabPanel-root.transaction-container {
        padding: 1rem 0 0 0;
    }
}