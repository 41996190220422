.secure-messaging-container {
    .messagesList, .messageView{
        ::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }
    
        ::-webkit-scrollbar
        {
            width: 6px;
            background-color: #F5F5F5;
        }
        ::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #cecece;
        }
    }

    .messagesList {
        .MuiButton-root.MuiButton-outlined.newMessageBtn {
            color: $brandColourPrimary;
            border: 0.12rem solid $brandColourPrimary;

            &:hover {
                background-color: #f2f2f2;
            }
        }
        .MuiTab-root {
            overflow: visible;
        }
        .MuiBadge-badge {
            margin: -0.2rem -0.4rem 0 0;
            font-size: 0.7rem;
            height: 17px;
            min-width: 17px;
            padding: 0 4px;
        }
        .MuiCardHeader-root {
            padding: 4px 4px 3px;

            .MuiCardHeader-title {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .messageTitle {
                    margin: 0;
                    font-weight: 600;
                }
                .messageTag {
                    color: #999999;
                    font-size: 0.7rem;
                    box-shadow: 0px 2px 2px -3px rgba(0, 0, 0, 0.2), 
                                0px 2px 3px 0px rgba(0, 0, 0, 0.14),
                                0px 1px 7px 0px rgba(0, 0, 0, 0.12);
                    padding: 0 3px;
                    height: 20px;
                }
            }
        }
        .messageDate {
            color: #999999;
            font-size: 0.7rem;
        }
        .MuiCardContent-root {
            padding: 2px 4px 3px;

            p {
                font-size: 0.8rem;
            }
        }
        .MuiCardActions-root {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.7rem;
            padding: 2px 4px 4px;
            justify-content: flex-end;

            .MuiSvgIcon-root {
                font-size: 0.9rem;
                color: rgba(0, 0, 0, 0.4);
                margin: 0 1px 0 10px;
            }
        }
        .messageItem {
            border-radius: 6px;;
            padding: 0 0.5rem;
            margin: 0.5rem 0;
        }
        .selectedMessage {
            background: #f5f5f5;
            box-shadow: 0 3px 10px rgba(0, 0,0 , 0.2);
        }
        .unreadMessage {
            border-left: 3px solid $brandColourPrimary;
        }
    }
    .messageView {
        .no-messages {
            padding-top: 0.7rem;
            font-size: 0.95rem;
            margin: 0;
        }
        .messageDate {
            color: #999999;
            font-size: 0.7rem;
        }
        .messageChainContainer {
            border-radius: 6px;
            box-shadow: 0 3px 10px rgba(0, 0,0 , 0.2);

            .MuiAvatar-root {
                background-color: #d1d1d1;
            }
        }
        .typeMessageField {
            border-radius: 6px;
            box-shadow: 0 3px 10px rgba(0, 0,0 , 0.2);
            padding: 0.2rem 0 0.2rem 0.5rem;
            margin: 1rem 0 0.5rem 0;

            .add-attachment {
                background: #f5f5f5;
                color: $brandColourPrimary;
                box-shadow: 0 3px 10px rgba(0, 0,0 , 0.2);

                .MuiSvgIcon-root {
                    transform: rotate(30deg);
                    font-size: 1.2rem;
                }
            }

            .MuiButton-contained {
                font-weight: 400;
                padding: 0.2rem 0.7rem;

                .MuiSvgIcon-root {
                    transform: rotate(-50deg);
                    margin-top: -6px;
                }
                
            }
        }
    }
    .messageFilters {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;

        span {
            font-size: 0.8rem;
        }
    }
    .messageHeader {
        margin: 0;

        h3 {
            margin: 2.7rem 0 2.5rem;
            font-size: 1.2rem;
        }
        .messageLinks {
            margin-top: -4.5rem;
            justify-content: flex-end;

            .MuiButton-root.MuiButton-text {
                color: #919191;
                font-size: 0.75rem;
                font-weight: 500;
                text-transform: none;

                &:hover {
                    color: $brandColourPrimary;
                }
            }
        }
    }
    .messageNote {       
        svg {
            color: #999;
            font-size: 2rem;
        }
    }
    .currentUserMessage {
        background: $brandColourPrimary;
        padding: 0.5rem 1rem;
        margin-top: 0.7rem;

        .MuiTypography-root {
            color: $buttonTextColour;
        }
    }
    .systemReplyMessage {
        padding: 0.5rem 1rem;
        margin-top: 0.7rem;
    }
    .MuiTablePagination-root {
        .MuiToolbar-root {
            padding: 0px;
            height: 100%;
        }
        padding: 0px;
        margin: 0px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.createNewMessage {
    .btn-sendMessage {
        .MuiSvgIcon-root {
            transform: rotate(-50deg);
            margin-top: -3px;
        }
    }
}

.add-attachments {
    label {
        font-size: 0.85rem;
        margin: 0 1rem 0.3rem;
        text-transform: uppercase;
    }
    .helper-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        margin: 0.4rem 0.8rem;
    }
    .upload-wrap {
        padding: 24px 0px;
        border: 2px dashed #ced4da;
        background: #f1f1f1;
        position: relative;

        &:hover {
            background: #f5f5f5;
        }

        .file-upload-input {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
            cursor: pointer;
        }

        .drag-text {
            text-align: center;
    
            h3 {
                color: $brandColourPrimary;
                font-size: 1.1rem;
                margin: 0 0 0.1rem 0;
            }
            p {
                color: #a9a9a9;
                font-size: 0.8rem;
                margin: 0;
            }
        }
    }
    .file-upload-details {
        margin-top: 3.5rem;
        display: flex;
        align-items: center;

        .img-thumb {
            padding: 0;
            border: none;
            margin-right: 0.5rem;
            border-radius: 0.25rem;
            max-width: 100%;
        }
        .file-filetype {
            background-color:#f1f1f1; 
            margin-right: 0.5rem;
            border-radius: 0.25rem;
            height: 60px;
            width: 60px;
            text-transform: uppercase;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 500;
        }
        .file-details {
            margin-left: 0.3rem;

            p {
                font-weight: 500;
                margin-bottom: 0.2rem;
                font-size: 0.95rem;
            }
            span {
                color: #a1a1a1;
                margin-left: 1.2rem;
                font-size: 0.9rem;
                font-weight: 400;
            }
        }
    }
}

@media only screen and (max-width: $mobileBreak) {
    .secure-messaging-container {
        .messagesList, .messageViewContainer {
            flex-basis: 100%;
            max-width: 100%;
            margin: 0;
        }
        .messageViewContainer {
            margin-top: 2rem;
        }
    }
}